export default [
  {
    width: 10,
    header: {
      type: 'text',
      caption: 'Currency',
    },
    cell: {
      type: 'text',
      value: (e) => e.currencyTitle,
    },
  },
  {
    width: 15,
    header: {
      type: 'text',
      caption: 'Balance in Staking',
    },
    cell: {
      type: 'slot',
      name: 'balance',
    },
  },
  {
    width: 15,
    header: {
      type: 'text',
      caption: 'Rewards to users',
    },
    cell: {
      type: 'slot',
      name: 'rewards',
    },
  },
];
