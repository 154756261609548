//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters, mapState } from 'vuex';
import constant from '~/const';

export default {
  props: {
    isLoading: { type: Boolean, default: false },
  },

  data() {
    return {
      filters: {
        dateFrom: null,
        dateTo: null,
        cumulativeCurrencyId: 2,
        userTagList: [],
      },
      constant,
    };
  },


  watch: {
    filters: {
      async handler(data) {
        await this.setFilters(data);
        this.$emit('update');
      },
      deep: true,
    },
  },

  computed: {
    ...mapGetters('currencies', ['getCurrencyByTitle']),
    ...mapState('users', ['userTagsList']),
    priceEquivalentList() {
      const { PRICE_EQUIVALENT_CURRENCY_LIST } = constant.currencies;
      const list = PRICE_EQUIVALENT_CURRENCY_LIST.map((e) => this.getCurrencyByTitle(e));
      return list;
    },
  },

  created() {
    this.setFilters({});
  },

  methods: {
    ...mapActions('statisticsStaking', {
      loadDataAction: 'loadData',
      setFilters: 'setFilters',
    }),
  },
};
