//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import * as d3 from 'd3';
import { mapState } from 'vuex';

export default {
  data() {
    return {
      COLOR: {
        BALANCE: '#2e75ee',
        REWARDS: '#ec0',
      },
    };
  },

  computed: {
    ...mapState('statisticsStaking', ['chart', 'additionalChart']),

    data() {
      const { chart } = this;
      return !chart.length ? [] : chart.map((e) => ({
        ...e,
        t: new Date(e.t * 1000),
      }));
    },

    yAxisInterval() {
      const { data } = this;
      const arr = [];

      data.forEach((e) => {
        arr.push(e.r);
      });

      const min = Math.min.apply(null, arr);
      const max = Math.max.apply(null, arr);

      return [min, max];
    },
  },

  watch: {
    chart() {
      this.drawChart();
    },
  },

  methods: {
    drawChart() {
      d3.selectAll('svg.staking-graph g').remove(); // remove all

      const {
        data,
        yAxisInterval,
        COLOR,
      } = this;
      const { clientWidth, clientHeight } = this.$refs.stakingGraph;
      const margin = {
        top: 20,
        right: 20,
        bottom: 30,
        left: 80,
      };
      const width = clientWidth - margin.left - margin.right;
      const height = clientHeight - margin.top - margin.bottom;

      const svg = d3.select('svg.staking-graph');

      const g = svg.append('g')
        .attr('transform', `translate(${margin.left}, ${margin.top})`);

      // set x-axis scale
      const x = d3.scaleTime()
        .rangeRound([0, width]);

      // set y-axis scale
      const y = d3.scaleLinear()
        .rangeRound([height, 0]);

      const rewardsLine = d3.line()
        .x((d) => x(d.t))
        .y((d) => y(d.r));

      // set range
      x.domain(d3.extent(data, (d) => d.t));
      y.domain(yAxisInterval);

      g.append('g')
        .attr('transform', `translate(0, ${height})`)
        .call(d3.axisBottom(x));

      g.append('g')
        .call(d3.axisLeft(y))
        .append('text')
        .attr('fill', '#000')
        .attr('transform', 'rotate(-90)')
        .attr('y', 6)
        .attr('dy', '0.7em')
        .attr('text-anchor', 'end')
        .text(`in ${this.additionalChart}`);

      g.append('path')
        .datum(data)
        .attr('fill', 'none')
        .attr('stroke', COLOR.REWARDS)
        .attr('stroke-linejoin', 'round')
        .attr('stroke-linecap', 'round')
        .attr('stroke-width', 1.5)
        .attr('d', rewardsLine);
    },
  },
};
