import { render, staticRenderFns } from "./StatisticsStakingFilters.vue?vue&type=template&id=6105f106&scoped=true&"
import script from "./StatisticsStakingFilters.vue?vue&type=script&lang=js&"
export * from "./StatisticsStakingFilters.vue?vue&type=script&lang=js&"
import style0 from "./StatisticsStakingFilters.vue?vue&type=style&index=0&id=6105f106&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6105f106",
  null
  
)

export default component.exports