//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapState, mapMutations } from 'vuex';
import constant from '~/const';
import StatisticsStakingFilters from './StatisticsStakingFilters.vue';
import StatisticsStakingChart from './StatisticsStakingChart.vue';
import tableConfig from './tableConfig';

export default {
  components: { StatisticsStakingFilters, StatisticsStakingChart },
  data() {
    return {
      constant,
      tableConfig,
      chartState: false,
      loader: true,
    };
  },

  computed: {
    ...mapState('common', ['generalLoader']),
    ...mapState('statisticsStaking', ['data', 'items', 'currentCurrencyId']),

    showTotalStaking() {
      return Object.keys(this.data).length;
    },

    isCurrentCurrencyAllowed() {
      return this.currentCurrencyId > 0;
    },
  },

  async mounted() {
    this.setGeneralProgress(true);
    await this.loadDataAction();
    await this.loadChartAction();
    this.setGeneralProgress(false);
  },

  methods: {
    ...mapActions('common', ['setGeneralProgress']),
    ...mapActions('statisticsStaking', {
      loadDataAction: 'loadData',
      loadChartAction: 'loadChart',
      applyFiltersAction: 'applyFilters',
    }),
    ...mapMutations('statisticsStaking', {
      setCurrentCurrencyIdAction: 'SET_CURRENT_CURRENCY_ID',
    }),

    async applyFilters() {
      this.setGeneralProgress(true);
      await this.loadDataAction();
      this.setGeneralProgress(false);
    },

    async setCurrentCurrency({ currencyId }) {
      this.setGeneralProgress(true);
      await this.setCurrentCurrencyIdAction(currencyId);
      await this.loadChartAction();
      this.setGeneralProgress(false);
    },
  },
};
